var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"basket__item\">\n  <img class=\"basket__image\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemImage") || (depth0 != null ? lookupProperty(depth0,"itemImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemImage","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":47}}}) : helper)))
    + "\" alt=\"img\" width=\"200\">\n  <p class=\"basket__name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"itemName") || (depth0 != null ? lookupProperty(depth0,"itemName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemName","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":38}}}) : helper)))
    + "</p>\n  <div class=\"quantity\">\n    <button class=\"decrement\">-</button>\n    <p class=\"item__quantity\">1</p>\n    <button class=\"increment\">+</button>\n  </div>\n  <p class=\"basket__price\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"itemPrice") || (depth0 != null ? lookupProperty(depth0,"itemPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemPrice","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":40}}}) : helper)))
    + "</p>\n  <button class=\"basket__itemDelete\">X</button>\n</div>";
},"useData":true});